import Vue from 'vue/dist/vue.esm'
import VueFormWizard from 'vue-form-wizard'
import Vuelidate from 'vuelidate'

Vue.use( Vuelidate )
Vue.use( VueFormWizard )

const {
  required,
  requiredIf,
  minLength,
} = require('vuelidate/lib/validators')

// if user has selected that they have made a prior Form D filing, they must provide an answer as to whether they want to use 'Basic' or 'Concierge' onboarding
const conciergeOnboardingValidValue = function ( value ) {
  if( this.issuer.has_made_form_d_filing == 'Yes' ) {
    if( value != '' )
      return true;
    else
      return false;
  } else
    return true;
}

document.addEventListener( 'DOMContentLoaded', () => {
  Vue.component( 'create_sponsor', {
    template: `<div>
            <div class="row form-group" v-bind:class="{ 'has-error': $v.sponsor.name.$error }">
              <label class="col-sm-3 col-form-label required font-bold">Sponsor Name</label>
              <div class="col-sm-9">
                <input class="form-control" v-model.trim="sponsor.name" @input="$v.sponsor.name.$touch()">
                <span class="form-text m-b-none">A "Sponsor" sponsors one or more "Issuers" (each fund is an issuer). For example, if Acme Management, LLC is the general partner of Acme Fund, LP, the Sponsor would be Acme Management, LLC and the issuer would be Acme Fund, LP. For private funds, the General Partner of the fund is typically the Sponsor. A Sponsor can sponsor multiple Issuers.</span>

                <span class="help-block" style="color: #ED5565;" v-if="$v.sponsor.name.$error && !$v.sponsor.name.required">Sponsor name is required</span>
              </div>
            </div>

            <div class="hr-line-dashed" />

            <div class="row form-group" v-bind:class="{ 'has-error': $v.sponsor.phone_number.$error }">
              <div class="col-sm-3">
                <label class="col-form-label required font-bold">Phone Number</label>
              </div>
              <div class="col-sm-9">
                <input class="form-control" v-model.trim="sponsor.phone_number" @input="$v.sponsor.phone_number.$touch()" placeholder="Phone Number">

                <span class="help-block" style="color: #ED5565;" v-if="$v.sponsor.phone_number.$error && !$v.sponsor.phone_number.required">Phone Number is required</span>
              </div>
            </div>

            <div class="hr-line-dashed" />

            <div class="row form-group" v-bind:class="{ 'has-error': $v.sponsor.address1.$error }">
              <div class="col-sm-3">
                <label class="col-form-label required font-bold">Mailing Address</label>
                <i class="fas fa-question-circle m-l" rel="tooltip" title= "" data-toggle="tooltip" data-placement="right" data-original-title="This address should be principal place of business for the sponsor / manager.  This address cannot be a PO Box." />
              </div>
              <div class="col-sm-9">
                <input class="form-control" v-model.trim="sponsor.address1" @input="$v.sponsor.address1.$touch()" placeholder="Address 1">

                <span class="help-block" style="color: #ED5565;" v-if="$v.sponsor.address1.$error && !$v.sponsor.address1.required">Address is required</span>
              </div>
            </div>

            <div class="row form-group">
              <div class="col-sm-9 offset-sm-3">
                <input class="form-control" v-model.trim="sponsor.address2" @input="$v.sponsor.address2.$touch()" placeholder="Address 2">
              </div>
            </div>

            <div class="row form-group">
              <div class="col-sm-3 offset-sm-3" v-bind:class="{ 'has-error': $v.sponsor.city.$error }">
                <input class="form-control" v-model.trim="sponsor.city" @input="$v.sponsor.city.$touch()" placeholder="City">
                <span class="help-block" style="color: #ED5565;" v-if="$v.sponsor.city.$error && !$v.sponsor.city.required">City is required</span>
              </div>

              <div class="col-sm-3" v-bind:class="{ 'has-error': $v.sponsor.state.$error }">
                <input class="form-control" v-model.trim="sponsor.state" @input="$v.sponsor.state.$touch()" placeholder="State">
                <span class="help-block" style="color: #ED5565;" v-if="$v.sponsor.state.$error && !$v.sponsor.state.required">State is required</span>
              </div>

              <div class="col-sm-3" v-bind:class="{ 'has-error': $v.sponsor.zip.$error }">
                <input class="form-control" v-model.trim="sponsor.zip" @input="$v.sponsor.zip.$touch()" placeholder="Zip">
                <span class="help-block" style="color: #ED5565;" v-if="$v.sponsor.zip.$error && !$v.sponsor.zip.required">Zip code is required</span>
              </div>
            </div>
          </div>`,
    data() {
      return {
        sponsor: {
          name: '',
          phone_number: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
        },
      }
    },
    validations: {
      sponsor: {
        name:     { required },
        phone_number: { required },
        address1: { required },
        address2: { },
        city:     { required },
        state:    { required },
        zip:      { required },
      },
      form: [ 'sponsor.name', 'sponsor.phone_number', 'sponsor.address1', 'sponsor.address2', 'sponsor.city', 'sponsor.state', 'sponsor.zip' ]
    },
    methods: {
      validate() {
        this.$v.form.$touch()
        var isValid = !this.$v.form.$invalid
        this.$emit('on-validate', this.$data, isValid)
        return isValid
      }
    }
  })

  Vue.component( 'create_issuer', {
    template: `<div>
            <div class="row form-group" v-bind:class="{ 'has-error': $v.issuer.name.$error }">
              <label class="col-sm-4 col-form-label required font-bold">Issuer Name</label>
              <div class="col-sm-8">
                <input class="form-control" v-model.trim="issuer.name" @input="$v.issuer.name.$touch()">
                <span class="form-text m-b-none">A "Sponsor" sponsors multiple "Issuers". If Acme Management, LLC manages Acme Fund, LP, the Issuer would be Acme Fund, LP.</span>

                <span class="help-block" style="color: #ED5565;" v-if="$v.issuer.name.$error && !$v.issuer.name.required">Issuer name is required</span>
              </div>
            </div>

            <div class="row form-group" v-bind:class="">
              <label class="col-sm-4 col-form-label required font-bold">Has this Issuer ever made a Form D Filing?</label>
              <div class="col-sm-8">
                <select class="form-control" style="max-width: 100px;" v-model="issuer.has_made_form_d_filing" @change="$v.issuer.has_made_form_d_filing.$touch()">
                  <option value></option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                <span class="help-block" style="color: #ED5565;" v-if="$v.issuer.has_made_form_d_filing.$error && !$v.issuer.has_made_form_d_filing.required">Please let us know if this issuer has ever made a Form D Filing</span>
              </div>
            </div>

            <div v-if="issuer.has_made_form_d_filing == 'Yes'">
              <div class="row form-group" v-bind:class="{ 'has-error': $v.issuer.concierge_onboarding.$error }">
                <label class="col-sm-4 col-form-label required font-bold">Do you want to self-populate historical "blue sky" filing data or would you like Blue Sky Compliance to assist in uploading historical "blue sky" filing data (for a $1,500 professional fee)?</label>
                <div class="col-sm-8">
                  <select class="form-control" style="max-width: 600px;" v-model="issuer.concierge_onboarding" @change="$v.issuer.concierge_onboarding.$touch()">
                    <option value="basic"></option>
                    <option value="basic">I will self-populate historical filing data</option>
                    <option value="concierge">I wish to engage Blue Sky Compliance under the Assisted Data Upload option</option>
                  </select>
                  <span class="help-block" style="color: #ED5565;" v-if="$v.issuer.concierge_onboarding.$error">Please select whether you would like to enter your past Blue Sky filings ('Basic' onboarding), or have Blue Sky Compliance enter your past filings ('Concierge' onboarding)</span>
                </div>
              </div>
            </div>

<!--
            <div v-if="issuer.has_made_form_d_filing == 'No' && showTooltips">
              <div class="hr-line-dashed" />
              <span class="help-block" style="color: #ED5565; font-weight: bold;">If this issuer has never made a Form D Filing, please provide us with the information below so that we can prepare a Form ID for the Issuer immediately</span>

              <br/>
              <br/>

              <div class="row form-group">
                <div class="col-sm-4">
                  <label class="col-form-label">"Doing Business As" Name</label>
                  <i class="fas fa-question-circle m-l" rel="tooltip" title= "" data-toggle="tooltip" data-placement="right" data-original-title="Complete this section only if the issuer is utilizing a DBA; otherwise leave blank" />
                </div>
                <div class="col-sm-8">
                  <input class="form-control" v-model.trim="issuer.dba_name">
                </div>
              </div>

              <div class="row form-group">
                <div class="col-sm-4">
                  <label class="col-form-label">Foreign Name</label>
                  <i class="fas fa-question-circle m-l" rel="tooltip" title= "" data-toggle="tooltip" data-placement="right" data-original-title="Complete this field only if the name of your company is in a language other than English" />
                </div>
                <div class="col-sm-8">
                  <input class="form-control" v-model.trim="issuer.foreign_name">
                </div>
              </div>

              <div class="row form-group">
                <div class="col-sm-4">
                  <label class="col-form-label">State of Incorporation</label>
                  <i class="fas fa-question-circle m-l" rel="tooltip" title= "" data-toggle="tooltip" data-placement="right" data-original-title="This is the State in which the Issuer was formed (not where the business is based)" />
                </div>
                <div class="col-sm-3">
                  <input class="form-control" v-model.trim="issuer.state_of_incorporation">
                </div>
              </div>

              <div class="row form-group">
                <div class="col-sm-4">
                  <label class="col-form-label">Fiscal Year End (MM/DD)</label>
                  <i class="fas fa-question-circle m-l" rel="tooltip" title= "" data-toggle="tooltip" data-placement="right" data-original-title="All entities that are taxed as partnerships typically have a 12/31 Fiscal Year -- but check with your CPA if you are not clear about this point" />
                </div>
                <div class="col-sm-2">
                  <input class="form-control" v-model.trim="issuer.fiscal_year_end">
                </div>
              </div>

              <div class="row form-group">
                <div class="col-sm-4">
                  <label class="col-form-label">Tax ID of Issuer</label>
                  <i class="fas fa-question-circle m-l" rel="tooltip" title= "" data-toggle="tooltip" data-placement="right" data-original-title="This is your US Federal EIN; if you do not have an EIN, enter '0'" />
                </div>
                <div class="col-sm-3">
                  <input class="form-control" v-model.trim="issuer.tax_id">
                </div>
              </div>

              <div class="row form-group">
                <div class="col-sm-4">
                  <label class="col-form-label">Primary Contact at Issuer</label>
                  <i class="fas fa-question-circle m-l" rel="tooltip" title= "" data-toggle="tooltip" data-placement="right" data-original-title="This is the first and last name of the person who is authorized to sign the Form D on behalf of the Issuer" />
                </div>
                <div class="col-sm-4">
                  <input class="form-control" v-model.trim="issuer.primary_contact">
                </div>
              </div>

              <div class="row form-group">
                <div class="col-sm-4">
                  <label class="col-form-label">Title of Primary Contact</label>
                  <i class="fas fa-question-circle m-l" rel="tooltip" title= "" data-toggle="tooltip" data-placement="right" data-original-title="This should be a basic title; the field is limited to 150 characters on Form D" />
                </div>
                <div class="col-sm-3">
                  <input class="form-control" maxlength="150" v-model.trim="issuer.primary_contact_title">
                </div>
              </div>
            </div>

            <div v-if="issuer.has_made_form_d_filing == 'Yes' && showTooltips">
              <div class="hr-line-dashed" />
              <span class="help-block" style="color: #ED5565; font-weight: bold;">If this issuer has made a Form D Filing, please provide us with the following values. If you do not know these fields, you can still submit the form, and we will work with you to gather them.</span>

              <br/>
              <br/>

              <div class="row form-group">
                <label class="col-sm-4 col-form-label">CIK code</label>
                <div class="col-sm-3">
                  <input class="form-control" v-model.trim="issuer.edgar_cik">
                </div>
              </div>

              <div class="row form-group">
                <label class="col-sm-4 col-form-label">Password</label>
                <div class="col-sm-3">
                  <input class="form-control" type="password" v-model.trim="issuer.edgar_password">
                </div>
              </div>

              <div class="row form-group">
                <label class="col-sm-4 col-form-label">CCC</label>
                <div class="col-sm-3">
                  <input class="form-control" v-model.trim="issuer.edgar_ccc">
                </div>
              </div>

              <div class="row form-group">
                <label class="col-sm-4 col-form-label">PMAC</label>
                <div class="col-sm-3">
                  <input class="form-control" v-model.trim="issuer.edgar_pmac">
                </div>
              </div>

              <div class="row form-group">
                <label class="col-sm-4 col-form-label">Passphrase</label>
                <div class="col-sm-3">
                  <input class="form-control" type="password" v-model.trim="issuer.edgar_passphrase">
                </div>
              </div>

              <div class="row form-group">
                <label class="col-sm-4 col-form-label">Accession Number</label>
                <div class="col-sm-3">
                  <input class="form-control" v-model.trim="issuer.edgar_accession_number">
                </div>
              </div>
            </div>
-->

            <br/>
          </div>`,
    data() {
      return {
        issuer: {
          name: '',
          has_made_form_d_filing: '',
          concierge_onboarding: ''
        }
      }
    },
    computed: {
      showTooltips: function( ) {
        // function must reference has_mode_form_d_filing, so that function return value isn't cached, and showTooltips( ) is called whenever has_made_form_d_filing is toggled
        if( this.issuer.has_made_form_d_filing ) {
          Vue.nextTick( function( ) {
              $( "[rel='tooltip']" ).tooltip( );
          });
        }

        return true;
      }
    },
    validations( ) {
      return {
        issuer: {
          name: { required },
          has_made_form_d_filing: { required },
          concierge_onboarding: { conciergeOnboardingValidValue } //, required: requiredIf( function( issuer ) { console.log( issuer.has_made_form_d_filing ) && issuer.has_made_form_d_filing == 'Yes' })},
        },
        form: [ 'issuer.name', 'issuer.has_made_form_d_filing', 'issuer.concierge_onboarding' ]
      }
    },
    methods: {
      validate() {
        this.$v.form.$touch();
        var isValid = !this.$v.form.$invalid
        this.$emit('on-validate', this.$data, isValid)
        return isValid
      }
    }
  })

  Vue.component( 'terms_of_use', {
    template: `<div>
              <iframe src="https://www.blueskycompliance.com/terms_raw" style="width: 100%; min-height: 500px;">
              </iframe>

            <div class="row form-group m-l m-t m-b">
              <div class="col-sm-1" v-bind:class="{ 'has-error': $v.agreement.user_agreement_signature.$error }">
                <label class="col-form-label font-bold">Signed:</label>
              </div>
              <div class="col-sm-8" v-bind:class="{ 'has-error': $v.agreement.user_agreement_signature.$error }">
                <input class="form-control" v-model.trim="agreement.user_agreement_signature" @input="$v.agreement.user_agreement_signature.$touch()" placeholder="Type your name as a signature">
                <span class="form-text m-b-none">By typing your name, you are confirming that you have read and agree to the license agreement.</span>

                <span class="help-block" style="color: #ED5565;" v-if="$v.agreement.user_agreement_signature.$error && !$v.agreement.user_agreement_signature.required">You must agree to the Terms and Conditions by signing your name</span>
              </div>
            </div>

            <div class="row form-group m-l m-t m-b">
              <div class="col-sm-1" v-bind:class="{ 'has-error': $v.agreement.user_agreement_signature_date.$error }">
                <label class="col-form-label font-bold">Dated:</label>
              </div>
              <div class="col-sm-8" v-bind:class="{ 'has-error': $v.agreement.user_agreement_signature_date.$error }">
                <input class="form-control" v-model.trim="agreement.user_agreement_signature_date" @input="$v.agreement.user_agreement_signature_date.$touch()">
                <span class="help-block" style="color: #ED5565;" v-if="$v.agreement.user_agreement_signature_date.$error && !$v.agreement.user_agreement_signature_date.required">You must date your signature</span>
              </div>
            </div>


<!--            <div class="i-checks">
              <label>
                <div class="icheckbox_square-green" style="position: relative;">
                  <input type="checkbox" value="" style="position: absolute; opacity: 0;">
                  <ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins>
                </div>
                <i></i> I have read and agree to the license agreement
              </label>
            </div>-->
          </div>`,
    data() {
      return {
        agreement: {
          user_agreement_signature: '',
          user_agreement_signature_date: ( new Date( )).toLocaleString( 'en-US', { month: 'long', day: 'numeric', year: 'numeric' })
        }
      }
    },
    validations: {
      agreement: {
        user_agreement_signature: { required },
        user_agreement_signature_date: { required }
      },
      form: [ 'agreement.user_agreement_signature', 'agreement.user_agreement_signature_date' ]
    },
    methods: {
      validate() {
        this.$v.form.$touch();
        var isValid = !this.$v.form.$invalid
        this.$emit('on-validate', this.$data, isValid)
        return isValid
      }
    }
  })

  const app = new Vue({
    el: '#vue_sponsor_issuer_wizard_validate',
    data: {
      finalModel: { },
    },
    methods: {
      onComplete( ) {
        $( '.wizard-footer-right button' ).prop( 'disabled', true );

        var csrfToken = $( 'meta[name=csrf-token]' )[0].content;
        var httpVars = Object.assign( {}, this.finalModel, { authenticity_token: csrfToken, utf8: "�~\~S" });

        this.postWith( '/portal/sponsors/create_sponsor_issuer', httpVars );
      },
      postWith( to, p ) {
        var myForm = document.createElement( 'form' );
        myForm.method = 'post';
        myForm.action = to;

        for( var k in p ) {
          var ele = p[k];

          if( typeof( ele ) === 'object' ) {
            for( var l in ele ) {
              var myInput = document.createElement( 'input' );
              myInput.setAttribute( 'name', k + '[' + l + ']' );
              myInput.setAttribute( 'value', ele[l] );
              myForm.appendChild( myInput );
            }
          } else {
            var myInput = document.createElement( 'input' );
            myInput.setAttribute( 'name', k );
            myInput.setAttribute( 'value', p[k] );
            myForm.appendChild( myInput );
          }
        }
        document.body.appendChild( myForm );
        myForm.submit( );
        document.body.removeChild( myForm );
      },
      validateStep( name ) {
        var refToValidate = this.$refs[name];
        return refToValidate.validate( );
      },
      mergePartialModels( model, isValid ) {
        if( isValid ) {
        // merging each step model into the final model
          this.finalModel = Object.assign({},this.finalModel, model)
        }
      }
    }
  })
})

